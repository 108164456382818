<template>
  <div>
    <!--begin::Dashboard-->

    <v-row>
      <v-col cols="12" :xl="isFromAdmission ? '12' : '4'">
        <v-row>
          <v-col v-if="isFromAdmission" cols="12">
            <p class="display-2">
              ¡Bienvenido/a!
            </p>
          </v-col>
          <v-col
           :class="`${isFromAdmission ? 'd-none':''}`"
            cols="12"
            :md="isFromAdmission ? '6' : '4'"
            :xl="isFromAdmission ? '6' : '6'"
          >
            <div
              class="white elevation-0 rounded-lg pa-5 card card-custom card-stretch card-shadowless"
            >
              <IconCardWithActionButtonVertical
                colorTheme="orange"
                title="Pago en línea"
                subTitle="¡Ahora puedes realizar los pagos del colegio!"
                icon="currency-usd"
                buttonText="Realizar pagos"
                @openDetailsModal="goToPaymentsView()"
              />
            </div>
          </v-col>
          <v-col
            v-if="false"
            cols="12"
            :md="isFromAdmission ? '6' : '4'"
            :xl="isFromAdmission ? '6' : '6'"
          >
            <div
              class="white elevation-0 rounded-lg pa-5 card card-custom card-stretch card-shadowless"
            >
              <IconCardWithActionButtonVertical
                colorTheme="blue"
                title="Sostenedor económico"
                subTitle="Formulario de registro de sostenedor económico del estudiante ante la institución."
                icon="account-outline"
                buttonText="Registrar sostenedor"
                @openDetailsModal="openRegisterEconomicManagerModal()"
              />
            </div>
          </v-col>
          <v-col v-if="!isFromAdmission" cols="12" md="4" xl="6">
            <div
              class="white elevation-0 rounded-lg pa-5 card card-custom card-stretch card-shadowless"
            >
              <IconCardWithActionButtonVertical
                colorTheme="blue"
                ac
                title="Carnet de asegurado"
                subTitle="Descarga el carnet de seguro de accidentes"
                icon="lifebuoy"
                buttonText="Descargar carnet seguro"
                @openDetailsModal="downloadCarnetSeguro()"
              />
            </div>
          </v-col>
          <v-col v-if="!isFromAdmission" cols="12" md="4" xl="12">
            <div
              class="white elevation-0 rounded-lg pa-5 card card-custom card-stretch card-shadowless"
            >
              <IconCardWithActionButtonVertical
                colorTheme="purple"
                title="Talonario de pagos"
                subTitle="Descarga el talonario de pagos para pagar en uno de los bancos autorizados (Banco Davivienda, Banco Agrícola o Banco Promerica)"
                icon="currency-usd"
                buttonText="Descargar talonario"
                @openDetailsModal="downloadTalonario()"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!isFromAdmission" cols="12" md="4" xl="3">
        <ListWidget3
          :isLoading="widgets.areStatsLoading"
          :currentPeriodDetails="yearlyProgress.activeStage"
          :yearProgress="yearlyProgress.elapsedYearPercent"
          :totalDays="yearlyProgress.totalDaysInYear"
          :elapsedDays="yearlyProgress.elapsedDays"
          :timeOfDayMessage="yearlyProgress.timeOfDayMessage"
          @statsLoaded="test()"
          @setDisciplineStats="loadDisciplineStats()"
        ></ListWidget3>
      </v-col>
      <v-col v-if="!isFromAdmission" cols="12" md="8" lg="8" xl="5">
        <ListWidget4
          :sortedStages="yearlyProgress.sortedStages"
          :activeStage="yearlyProgress.activeStage"
          :isLoading="widgets.areStatsLoading"
        ></ListWidget4>
      </v-col>
    </v-row>

    <div
      v-if="widgets.latestGradedSubevaluations.length > 0"
      class="card card-custom gutter-b pa-6"
    >
      <v-row>
        <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
          <v-col v-if="!widgets.isLoading" class="" cols="12">
            <p class="text-center display-1 mb-0 ml-2">
              Últimas evaluaciones calificadas
            </p>
          </v-col>
        </v-scroll-x-transition>

        <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
          <v-col v-if="widgets.isLoading" cols="12">
            <v-card
              height="250"
              class="pa-4 rounded-lg d-flex justify-center align-center flex-column elevation-0"
            >
              <v-progress-circular
                :size="50"
                indeterminate
                color="primary"
                class="mb-4"
              ></v-progress-circular>
              <p class="text-center font-weight-medium text-body-1">
                Buscando últimas evaluaciones calificadas...
              </p>
            </v-card>
          </v-col>
        </v-scroll-x-transition>

        <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
          <v-row v-if="!widgets.isLoading" class="mx-2">
            <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
              v-for="(
                subEvaluation, index
              ) in widgets.latestGradedSubevaluations"
              :key="index"
            >
              <ListWidget11 :subEvaluation="subEvaluation"></ListWidget11>
            </v-col>
          </v-row>
        </v-scroll-x-transition>
      </v-row>
    </div>
    <registerStudentEconomicManagerModal
      ref="mdlRegisterStudentEconomicManager"
    ></registerStudentEconomicManagerModal>
  </div>
</template>

<script>
import dashboardRepository from "@/repositories/dashboardRepository";
import IconCardWithActionButtonVertical from "@/view/components/dashboard/IconCardWithActionButtonVertical";
import registerStudentEconomicManagerModal from "@/view/components/modals/payments/registerStudentEconomicManagerModal.vue";

import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import ListWidget2 from "@/view/content/widgets/list/Widget2.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget5 from "@/view/content/widgets/list/Widget5.vue";
import ListWidget10 from "@/view/content/widgets/list/Widget10.vue";
import ListWidget11 from "@/view/content/widgets/list/Widget11.vue";
import ListWidget12 from "@/view/content/widgets/list/Widget12.vue";
import ListWidget13 from "@/view/content/widgets/list/Widget12.vue";
import ListWidget14 from "@/view/content/widgets/list/Widget14.vue";
import ListWidget15 from "@/view/content/widgets/list/Widget15.vue";
import ListWidget16 from "@/view/content/widgets/list/Widget16.vue";

export default {
  name: "dashboard",
  components: {
    IconCardWithActionButtonVertical,
    AdvancedTableWidget2,
    registerStudentEconomicManagerModal,
    MixedWidget1,
    ListWidget2,
    ListWidget3,
    ListWidget4,
    ListWidget5,
    ListWidget10,
    ListWidget11,
    ListWidget12,
    ListWidget13,
    ListWidget14,
    ListWidget15,
    ListWidget16,
  },
  data() {
    return {
      widgets: {
        isLoading: true,
        areStatsLoading: true,
        latestGradedSubevaluations: [],
        stageDetails: {},
        paymentStatus: {},
        stages: [],
      },
      yearlyProgress: {},
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    // this.getLatestGradedSubevaluations();
    this.setProgressAndStatus();

    if ("economic_manager_exists" in this.currentUserPersonalInfo) {
      if (
        !this.currentUserPersonalInfo.economic_manager_exists ||
        this.currentUserPersonalInfo.show_economic_manager_update_form
      ) {
        this.openRegisterEconomicManagerModal();
      }
    }
  },
  watch: {
    "currentUserPersonalInfo.grade_id": function (newValue, oldValue) {
      if (!!newValue) {
        this.setProgressAndStatus();
      }
    },
  },
  methods: {
    opeStudentForm() {
      window.location.href = `${this.studentFormUrl}/student/${this.currentUserPersonalInfo.uuid}`;
    },
    openRegisterEconomicManagerModal() {
      this.$refs.mdlRegisterStudentEconomicManager.toggleModal();
    },
    getYearlyProgress() {
      dashboardRepository
        .getStudentStagesByGrade(this.currentUserPersonalInfo.grade_id)
        .then(({ data }) => {
          this.yearlyProgress = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToPaymentsView() {
      this.$router.push({
        path: "online-payments",
      });
    },

    downloadTalonario() {
      try {
        const downloadLink = document.createElement("a");
        downloadLink.href = this.currentUserPersonalInfo.talonario;
        downloadLink.download = `${this.currentUserPersonalInfo.code}-talonario.pdf`; // Change 'output.pdf' to the desired filename
        downloadLink.click();
      } catch (error) {
        this.fireToast({
          icon: "error",
          title: "Hubo un error al intentar descargar el talonario",
        });
      }
    },

    downloadCarnetSeguro() {
      try {
        const downloadLink = document.createElement("a");
        downloadLink.href = this.currentUserPersonalInfo.seguro;
        downloadLink.download = `${this.currentUserPersonalInfo.code}-carnet-seguro.pdf`; // Change 'output.pdf' to the desired filename
        downloadLink.click();
      } catch (error) {
        this.fireToast({
          icon: "error",
          title: "Hubo un error al intentar descargar el carnet de seguro",
        });
      }
    },
    test() {
      this.widgets.areStatsLoading = false;
    },
    loadDisciplineStats() {
      //The current discipline stats will depend on the given current active stage.
    },
    setProgressAndStatus() {
      if (!!this.currentUserPersonalInfo.grade_id) {
        this.widgets.areStatsLoading = true;
        this.getYearlyProgress();
      }
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    // setActiveTab2(event) {
    //   this.tabIndex2 = this.setActiveTab(event);
    // },
    getLatestGradedSubevaluations() {
      this.widgets.isLoading = true;

      dashboardRepository
        .getLatestGradedSubevaluations()
        .then(({ data }) => {
          this.widgets.latestGradedSubevaluations = data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.widgets.isLoading = false;
        });
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    admisionURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }
      return baseURL;
    },
    isFromAdmission() {
      return this.currentUserPersonalInfo.is_from_admision;
    },
    studentFormUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.FORM_PRODUCTION_DOMAIN;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.FORM_PRODUCTION_DOMAIN;
      } else {
        baseURL = process.env.FORM_PRODUCTION_DOMAIN;
      }
      return baseURL;
    },
    studentCode() {
      return this.currentUserPersonalInfo.code;
    },
    gradeId() {
      return this.currentUserPersonalInfo.grade_id;
    },
    specialityId() {
      return this.currentUserPersonalInfo.specialityId_id;
    },
    currentPeriod() {
      const today = new Date();
    },
    currentDate() {
      const today = new Date();
      const formatter = new Intl.DateTimeFormat("es-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const fullDate = formatter.format(today);
      return fullDate;
    },
  },
};
</script>
